import React, { useState, useEffect, useMemo } from "react"
import { debounce } from "../../utilities/debounce";
import * as styles from './QuantitySelector.module.scss';
import { ButtonResult, ButtonType, PopupSize, popup, Loader } from "ui";
import cn from 'classnames';
import { RemanResults } from "../../types";
import { Adaptive } from "../Adaptive/Adaptive";

enum ButtonState {
    Normal,
    Loader,
    LoadedLabel,
    LoadedReman,
    RemanChosen,
    RemovingItem,
    Added
}

const cancelDefault = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
}

function isNumeric(str: string | number) {
    if (typeof str != "string") return false;
    return !isNaN(str as unknown as number) && !isNaN(parseFloat(str));
}

const clamp = (v: number, min: number, max: number) => Math.min(max, Math.max(min, v));

const translations = (window as any).app.preloadState.translation;
const loginLink = (window as any).app.preloadState.loginLink;

const tr = Object.fromEntries(Object.entries(translations)
    .filter(pair => pair[0].startsWith("quantitySelector."))
    .map(pair => ([pair[0].split('.')[1], pair[1] as string] as const)));

export const showNotAuthorizedPopup = (ev: React.MouseEvent) => {
    ev.preventDefault();
    popup(
        tr.cannotBuy,
        <div>
            {tr.insufficientRightsToBuy}<br /><br />
            <a href="https://myswecon.swecon.se/mitt-konto/hantera-anvandare/">{tr.contactMySweconAdministrator}</a>
        </div>,
        [
            { label: tr.close, result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Small
    );
}

export enum Mode {
    LoginToBuy,
    NotAuthorized,
    RequestQuotationByEmail,
    ContactSupport,
    Buy,
    AddToCart,
    NotAvailableAndDisabled,
    UsedPart
}

export enum Design {
    Normal,
    PDP,
    New
}

type QuantitySelectorProps = {
    step: number;
    min: number;
    max: number;
    initialValue: number;
    disabled?: boolean;
    onChange: (value: number) => void;
    onRemove: (doAfter: () => void) => void;
    onAddReman?: (value: number) => void;
    forceMinValue: boolean;
    mode: Mode;
    requestQuotationByEmail: string | null;
    contactSupportLink: string | null;
    className?: string;
    showRemanufacturePopUp?: () => Promise<RemanResults[string] | undefined>;
    buttonClassName?: string;
    hasRemanInCart?: boolean;
    hasRemanAvailable?: boolean;
    design?: Design;
}

export const QuantitySelector = ({
    step,
    min,
    max,
    initialValue,
    disabled,
    onChange,
    onRemove,
    onAddReman = () => { },
    forceMinValue,
    mode,
    requestQuotationByEmail,
    contactSupportLink,
    className = "",
    showRemanufacturePopUp = undefined,
    buttonClassName = "btn--primary",
    hasRemanInCart,
    hasRemanAvailable = false,
    design = Design.New
}: QuantitySelectorProps) => {

    switch (mode) {
        case Mode.LoginToBuy:
            return <a className={cn(styles.buyButton, "btn btn--outlined justify-content-center", styles.buttonStyledAnchor)} href={loginLink}>{tr.login}</a>
        case Mode.NotAuthorized:
            return <span className={cn(styles.buyButton, "btn btn--outlined  justify-content-center", styles.buttonStyledAnchor)}
                title={tr.insufficientRightsToBuy}
                onClick={showNotAuthorizedPopup}>{tr.moreInformation}</span>;
        case Mode.RequestQuotationByEmail:
            return <a className={cn(styles.buyButton, "btn btn--outlined justify-content-center", styles.buttonStyledAnchor)} href={requestQuotationByEmail ?? ""}>{tr.requestForQuotation}</a>;
        case Mode.NotAvailableAndDisabled:
            return <a className={cn(styles.buyButton, "btn btn--primary justify-content-center", styles.disabled, styles.buttonStyledAnchor)}>{tr.notAvailable}</a>;
        case Mode.ContactSupport:
            return <a className={cn(styles.buyButton, "btn btn--outlined justify-content-center", styles.buttonStyledAnchor)} href={contactSupportLink ?? ""}>{tr.contactSupport}</a>;
        case Mode.UsedPart:
        case Mode.AddToCart:
        case Mode.Buy:
            break;
        default:
            return <span></span>;
    }

    const maxTruncated = useMemo(() => Math.trunc(max / step) * step, [max, step]);

    const [textValue, setTextValue] = useState(initialValue.toString());
    const [lastValidValue, setLastValidValue] = useState(initialValue);
    const [shouldShowButton, setShowButton] = useState(initialValue === 0);
    const [infoMessage, setInfoMessage] = useState("");
    const [buttonState, setButtonState] = useState(hasRemanInCart ? ButtonState.RemanChosen : ButtonState.Normal);

    const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange]);

    useEffect(() => {
        if (!disabled && checkForErrors(textValue, lastValidValue) === null && lastValidValue > 0) {
            debouncedOnChange(lastValidValue);
        }
    }, [lastValidValue, textValue]);

    const updateValue = (text: string | number) => {
        setInfoMessage("");

        if (typeof text === 'number') {
            text = text.toString();
        }
        setTextValue(text);
        if (isNumeric(text)) {
            const num = +text;
            min = Math.max(min, 1);
            setLastValidValue(num);
            if (forceMinValue && num < min) {
                setLastValidValue(min);
                setTextValue(min.toString());
            }
        }
    };

    const updateButton = (v: number) => {
        const shouldRemove = v === 0;
        if (shouldRemove) {
            debouncedOnChange.stop();
            onRemove(() => setShowButton(true));
        } else {
            setShowButton(false);
        }
    }

    const add = async () => {

        const addSparePartToCart = (quantity?: number) => {
            setButtonState(ButtonState.Loader);
            (quantity == 0) ?
                setTimeout(() => setButtonState(ButtonState.RemovingItem), 1000)
                :
                setTimeout(() => setButtonState(ButtonState.LoadedLabel), 1000)

            setTimeout(() => setButtonState(ButtonState.Normal), 2000);
            updateValue(quantity ?? step);
            updateButton(quantity ?? step);
        }

        const addRemanItemToCart = (quantity: number) => {
            onAddReman(quantity);

            setButtonState(ButtonState.Loader);
            setTimeout(() => setButtonState(ButtonState.LoadedReman), 1000);
            setTimeout(() => setButtonState(ButtonState.RemanChosen), 2000);
        }

        const addUsedItemToCart = (quantity: number) => {
            setButtonState(ButtonState.Loader);
            setTimeout(() => setButtonState(ButtonState.Added), 1000);
            updateValue(quantity ?? step);
            updateButton(quantity ?? step);
        }

        if (mode === Mode.UsedPart) {
            addUsedItemToCart(1);
            return;
        }
        if (!showRemanufacturePopUp) {
            addSparePartToCart();
        } else {
            const popupData = await showRemanufacturePopUp();
            if (!popupData) {
                //undefined, user canceled the pop up
                //do nothing
                return;
            }
            if (popupData.selectedReman) {
                addRemanItemToCart(popupData.remanQuantity);
            } else {
                addSparePartToCart(popupData.sparePartQuantity);
            }
        }
    };

    const increment = () => {
        const intendedValue = Math.trunc((lastValidValue + step) / step) * step;
        const possibleValue = clamp(intendedValue, min, maxTruncated)
        updateValue(possibleValue);

        if (intendedValue > possibleValue)
            setInfoMessage(tr.maxNumberOfItems ?? "");
    }

    const decrement = () => {
        const newValue = clamp(Math.trunc((lastValidValue - step) / step) * step, 0, max);
        updateValue(newValue);
        if (!forceMinValue) {
            updateButton(newValue);
        }
    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
            e.currentTarget.blur();
        }
    }

    const checkForErrors = (textValue: string, numberValue: number) => {
        if (numberValue.toString() !== textValue) {
            return tr.invalidValue;
        }
        if (numberValue === 0) {
            return tr.productRemoved;
        }
        if (numberValue < min) {
            return tr.valueTooLow;
        }
        if (numberValue > max) {
            return tr.valueTooHigh;
        }
        if (numberValue % step !== 0) {
            return tr.cannotDivideUnit;
        }
        return null;
    };

    const errorMessage = checkForErrors(textValue, lastValidValue);
    const checkButton = () => updateButton(lastValidValue);

    const MainButton = () => {
        switch (buttonState) {
            case ButtonState.Loader:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName, styles.buttonLabelLoadingState)}>
                    <Loader/>
                </button>;
            case ButtonState.LoadedLabel:
                return <button className={cn(styles.buyButton, "btn", buttonClassName, styles.buttonLabelAddedState)}>
                    <span className={styles.buttonLabelAddedStateIcon}></span><span className={cn("firstLetterCapital", styles.buttonLabelAddedStateLbl)}>{tr.buttonLabelAdded}</span>
                </button>;
            case ButtonState.LoadedReman:
                return <button className={cn(styles.buyButton, "btn", buttonClassName, styles.buttonLabelAddedState)}>
                    <span className={styles.buttonLabelAddedStateIcon}></span><span className={cn("firstLetterCapital", styles.buttonLabelAddedStateLbl)}>{tr.buttonLabelRemanAdded}</span>
                </button>;
            case ButtonState.RemanChosen:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)} onClick={add}>
                    {tr.buttonLabelReman}
                </button>;
            case ButtonState.RemovingItem:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)}>
                    {tr.itemRemoved}
                </button>;
            case ButtonState.Added:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)} onClick={decrement}>
                    {tr.added}
                </button>;
            default:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)} onClick={add}>
                    {hasRemanAvailable
                        ? tr.buttonLabelRemanBase
                        : (mode === Mode.AddToCart
                            ? <Adaptive breakpoint="lg" over={tr.buttonLabelAddToCart} under={tr.buttonLabelAddToCartMobile} />
                            : tr.buttonLabel)}
                </button>;
        };
    };

    const NumberInput = () => <div className={styles.numberInput}>
        <div className={styles.input}>
            <input disabled={disabled} onKeyUp={onEnter} type="number" value={textValue} onBlur={checkButton} onInput={(e) => updateValue(e.currentTarget.value)}></input>
            <div className={styles.note}>{tr.countInCart}</div>
            <div className={styles.inputChevrons}>
                <div className={cn(styles.chevronUp, disabled && styles.disabled)} onClick={increment}>
                    <div>&#x2039;</div>
                </div>
                <div className={cn(styles.chevronDown, disabled && styles.disabled)} onClick={decrement}>
                    <div>&#x2039;</div>
                </div>
            </div>
        </div>
        {errorMessage !== null && (
            <div className={styles.error}>{errorMessage}</div>
        )}
        {errorMessage == null && infoMessage?.length > 0 && (
            <div className={styles.error}>{infoMessage}</div>
        )}
    </div>;

    const designStyle = useMemo(() => {
        switch(design) {
            case Design.New:
                return styles.newDesign;
            case Design.PDP:
                return styles.pdpDesign;
            default:
                return "";
        };
    }, [design]);

    return (
        <div onClick={cancelDefault} className={cn(className, designStyle)}>
            {shouldShowButton || buttonState !== ButtonState.Normal ? <MainButton />: <NumberInput />}
        </div>
    );
}
